import Phaser, { GameObjects, Scene } from 'phaser';

import { EventBus } from '../EventBus';
import { PageIndexHeroes } from '../main';
import { SpineGameObject } from '@esotericsoftware/spine-phaser';
import { sleep } from 'utils';

export class MainMenu extends Scene {
  constructor() {
    super('MainMenu');
  }

  async create() {
    while (!this.add.spine) {
      console.log('not spine');
      await sleep(1000);
    }
    const atkMap: Record<string, { hero: SpineGameObject; conf: (typeof PageIndexHeroes)[number] }> = {};
    PageIndexHeroes.forEach((conf) => {
      const hero = this.add.spine(conf.left, conf.top, `${conf.name}-json`, `${conf.name}-atlas`);
      atkMap[conf.name] = { hero, conf };
      hero.scaleY = conf.scaleY;
      hero.scaleX = conf.scaleX;
      hero.setInteractive();
      // console.log(hero.animationStateData.skeletonData);
      hero.animationState.setAnimation(0, 'idle', true);
      console.log(
        conf.name,
        hero.animationStateData.skeletonData.animations.map((a) => a.name),
      );
      const resetHero = () => {
        this.tweens.add({ targets: hero, scaleX: conf.scaleX, scaleY: conf.scaleY, duration: 200 });
      };
      hero.animationState.addListener({
        event: function (entry, event) {
          console.log(conf.name, 'event', event);
        },
        complete(entry) {
          if (entry.animation?.name !== 'attack') return;
          console.log(conf.name, 'complete', entry);
          // conf.atks.forEach((target) => {
          //   atkMap[target]?.hero.animationState.setAnimation(0, 'hit', false);
          //   atkMap[target]?.hero.animationState.addAnimation(0, 'idle', true, 0);
          // });
        },
      });
      hero.on('clicked', () => {
        // if (conf.name === 'Thief') {
        //   const tHero = atkMap[conf.atks[0]]?.hero;
        //   if (!tHero) return;
        //   // this.tweens.add({ targets: [hero], x: hero.x + 100, alpha: 0, alphaLeft: 0, alphaRight: 0, alphaBottom: 0, alphaTop: 0, duration: 2000, ease: 'Sine.easeInOut' });
        //   const origin = { x: hero.x, y: hero.y, z: hero.z };
        //   hero.setX(tHero.x - 780);
        //   hero.setY(tHero.y);
        //   hero.setDepth(1);
        //   hero.animationState.setAnimation(0, 'attack', false).listener = {
        //     complete: () => {
        //       hero.setX(origin.x);
        //       hero.setY(origin.y);
        //       hero.setDepth(origin.z);
        //     },
        //   };
        //   hero.animationState.addAnimation(0, 'idle', true, 0);
        //   return;
        // }
        hero.animationState.setAnimation(0, 'attack', false);
        hero.animationState.addAnimation(0, 'idle', true, 0);
        if (conf.name === 'RadiantMage') {
          setTimeout(() => {
            conf.atks.forEach((target) => {
              const to = atkMap[target]?.hero;
              if (!to) return;
              const fromXY = { x: hero.x + 420, y: hero.y - 220 };
              const toXY = { x: to.x, y: to.y - 140 };
              const rotation = Phaser.Math.Angle.Between(fromXY.x, fromXY.y, toXY.x, toXY.y);
              // const vec = new Phaser.Math.Vector2(tx - x, ty - y)
              // const rotation = vec.angle()
              const lines = new Array(10).fill(0).map(() => this.add.image(fromXY.x, fromXY.y, 'line').setRotation(Math.PI / 2 + rotation));
              lines[0].setAlpha(0);
              this.tweens.add({
                targets: lines,
                x: toXY.x,
                y: toXY.y,
                duration: 200,
                alpha: { from: 0.3, to: 1 },
                delay: (tar: any, tKey: any, value: any, tIndex: number, total: any, tween: any) => {
                  return tIndex * 10;
                },
                onComplete: () => {
                  to.animationState.setAnimation(0, 'hit', false);
                  to.animationState.addAnimation(0, 'idle', true, 0);
                  lines.forEach((l) => l.destroy());
                },
              });
            });
          }, 600);
        }
      });
      hero.on('GAMEOBJECT_OVER', () => {
        // this.tweens.add({ targets: hero, scaleX: conf.scaleX * 1.1, scaleY: conf.scaleY * 1.1, duration: 200 });
      });
      hero.on('GAMEOBJECT_OUT', () => {
        // resetHero();
      });
    });
    this.input.on(Phaser.Input.Events.GAMEOBJECT_DOWN, (aaa: any, bbb: any) => {
      bbb.emit('clicked', bbb);
    });
    this.input.on(Phaser.Input.Events.GAMEOBJECT_OVER, (aaa: any, bbb: any) => {
      bbb.emit('GAMEOBJECT_OVER', bbb);
    });
    this.input.on(Phaser.Input.Events.GAMEOBJECT_OUT, (aaa: any, bbb: any) => {
      bbb.emit('GAMEOBJECT_OUT', bbb);
    });

    EventBus.emit('current-scene-ready', this);
    EventBus.on('change-action', async (action: string) => {
      // FallenProphet.animationState.setAnimation(0, action, true);
    });
  }
}
