import { LayoutDefault } from 'components/layouts/LayoutDefault';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import BackgroundImage from 'assets/images/index/about.jpg';
import IndImg1 from 'assets/images/index/about-1.jpg';
import IndImg2 from 'assets/images/index/about-2.jpg';
import IndImg3 from 'assets/images/index/about-3.jpg';
import { ReactComponent as IndSvg } from 'assets/images/index/about.svg';
import { ReactComponent as IndSvg1 } from 'assets/images/index/about-1.svg';
import { ReactComponent as IndSvg2 } from 'assets/images/index/about-2.svg';
import { ReactComponent as IndSvg3 } from 'assets/images/index/about-3.svg';

const Items = [
  { text: 'Crypto Rumble once won the first place in the 2023 Binance Hackathon', bg: IndImg1, icon: <IndSvg1 width={80} /> },
  {
    text: `Crypto Rumble a full chain game`,
    node: (
      <>
        Crypto Rumble
        <br />a full chain game
      </>
    ),
    bg: IndImg2,
    icon: <IndSvg2 width={80} />,
  },
  { text: 'Use zero-knowledge proof to ensure the fairness and validity of the game', bg: IndImg3, icon: <IndSvg3 width={80} /> },
];

export const IndexAbout: React.FC<{}> = (props) => {
  return (
    <IndexAboutStyle style={{ backgroundImage: `url(${BackgroundImage})` }}>
      <div className="text">
        <div className="title">About Us</div>
        <div className="des">Learn about Crypto Rumble Studio</div>
      </div>
      <div className="items">
        {Items.map((item) => {
          return (
            <div className="item" key={item.text}>
              <div className="img">
                <img src={item.bg} />
                <div className="icon">{item.icon}</div>
              </div>
              <div className="text">{item.node || item.text}</div>
            </div>
          );
        })}
      </div>

      <div className="PowerdBy">Powerd by Zypher Game</div>
      <IndSvg className="pow-svg" />
    </IndexAboutStyle>
  );
};

const IndexAboutStyle = styled.div`
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  gap: 20px;
  > .PowerdBy {
    padding: 30px 0 10px;
    font-size: 32px;
    font-weight: 700;
  }
  > .pow-svg {
    max-width: 90vw;
  }
  > .items {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    > .item {
      width: 393px;
      height: 210px;
      background-color: #fff;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      max-width: 90vw;
      border-bottom: 6px solid rgba(250, 190, 75, 1);
      > .img {
        position: relative;
        transform: scale(1);
        overflow: hidden;
        cursor: pointer;
        > img {
          transition: all ease-in-out 1s;
          width: 100%;
          display: block;
        }
        > .icon {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background-color: #00000055;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      > .text {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px 16%;
      }
      &:hover {
        animation: headShake 0.8s ease-in-out;
        > .img {
          > img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  > .text {
    color: #000;
    text-align: center;
    > .title {
      font-size: 32px;
      font-weight: 700;
    }
    > .des {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;
