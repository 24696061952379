import { LayoutDefault } from 'components/layouts/LayoutDefault';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ViewBg1 from 'assets/images/airdrop/view-bg-1.jpg';
import ViewBg2 from 'assets/images/airdrop/view-bg-2.jpg';
import ViewBox from 'assets/images/airdrop/box.png';
import ViewBox2 from 'assets/images/airdrop/box-2.png';
import ViewBoxs from 'assets/images/airdrop/box-s.png';
import ViewBoxBg from 'assets/images/airdrop/box-bg.png';
// import NoteX from 'assets/images/airdrop/x.png';
import NoteQ from 'assets/images/airdrop/q.png';
import DataAblePng from 'assets/images/airdrop/able.png';
import DataEthPng from 'assets/images/airdrop/eth.png';
import DataClaimedPng from 'assets/images/airdrop/claimed.png';
import { PageAirdropPcP } from './pcp';
import { PageAirdropHeroes } from './heroes';
import { useAccount, useReadContracts } from 'wagmi';
import Restaking from 'types/crypto-rumble-periphery/abis/Restaking';
import { useRecoilValue } from 'recoil';
import { stateAppChainInfo } from 'states/wallet';
import { zeroAddress } from 'viem';
import HeroEdition2 from 'types/crypto-rumble-periphery/abis/HeroEdition2';
import { fmtInt, fmtNumber } from 'utils';
import { AppButton } from 'components/app/AppButton';
import { GlobalVar } from 'constants/constants';
import { useWallet } from 'hook/useWallet';

const SymbolValueMap = {
  ETH: 0.5,
  BTC: 0.025,
};

export const PageAirdrop: React.FC<{}> = (props) => {
  const chainInfo = useRecoilValue(stateAppChainInfo);
  const wallet = useWallet();

  const infos = useReadContracts({
    contracts: [
      { abi: Restaking, address: chainInfo.Restaking, functionName: 'userInfo', args: [wallet.address, chainInfo.WETH] },
      { abi: HeroEdition2, address: chainInfo.HeroEdition2.address, functionName: 'getMintAmount', args: [chainInfo.WETH, wallet.address] },
      { abi: HeroEdition2, address: chainInfo.HeroEdition2.address, functionName: 'getMaxStakersMint' },
      { abi: HeroEdition2, address: chainInfo.HeroEdition2.address, functionName: 'getTotalMinted' },
      { abi: HeroEdition2, address: chainInfo.HeroEdition2.address, functionName: 'getStakerMinted', args: [wallet.address] },
    ],
    allowFailure: false,
    query: {
      refetchInterval: 10000,
      select(data) {
        console.log(data);
        return {
          userInfo: wallet.address === zeroAddress || data[0][0] > BigInt(Math.floor(Date.now() / 1000)) ? data[0] : null,
          getMintAmount: data[1][0],
          getMaxStakersMint: data[2],
          getTotalMinted: data[3],
          getStakerMinted: data[4],
        };
      },
    },
  });
  return (
    <LayoutDefault>
      <PageIndexStyle>
        <div className="view">
          <div className="bg">
            <img src={ViewBg1} />
            <div />
          </div>
          <div className="title">Stake {wallet.chainInfo.nativeCurrency.symbol}</div>
          <div className="des">Get CryptoRumble hero mystery boxes</div>
          <div className="content">
            <img className="box" width={327} src={ViewBox} />
            <PageAirdropHeroes />
            <div className="datas">
              <DataItem img={<img src={DataEthPng} width={30} />} name={`Staked ${wallet.chainInfo.nativeCurrency.symbol}`} value={fmtNumber(infos.data?.userInfo?.[1], 18, 4)} />
              <div className="hr" />
              <DataItem img={<img src={DataAblePng} width={42} style={{ marginRight: -6, marginLeft: -6 }} />} name="Available" value={`x${fmtInt(infos.data?.getMintAmount)}`} />
              <div className="hr" />
              <DataItem img={<img src={DataClaimedPng} width={30} />} name="Claimed" value={`x${fmtInt(infos.data?.getStakerMinted)}`} />
            </div>
            <div className="progress">
              {fmtInt(infos.data?.getTotalMinted)}
              <span>/ {fmtInt(infos.data?.getMaxStakersMint)}</span>
            </div>
            <PageAirdropPcP fontSize={24}>Remaining Claims</PageAirdropPcP>
            <AppButton
              style={{ marginTop: 60, width: 200, height: 60 }}
              onClick={wallet.requestTx(
                async () => {
                  if (!infos.data?.getMintAmount) {
                    GlobalVar.modal.confirm({
                      wrapClassName: 'app-confirm-wrap',
                      centered: true,
                      maskClosable: true,
                      title: 'NOTE',
                      icon: null,
                      width: 600,
                      content: (
                        <ConfirmContent
                          img={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={ViewBox2} width={128} />
                              <img src={NoteQ} width={32} style={{ marginTop: -40, marginLeft: -40 }} />
                            </div>
                          }
                          title="You are not yet qualified to mint"
                          des={`Please go to the staking page to pledge ${wallet.chainInfo.nativeCurrency.symbol} to obtain minting qualifications`}
                        />
                      ),
                      footer: (
                        <AppButton link="https://test.zypher.game/#/airdrop/staking" className="app-confirm-btn" style={{ width: 178, height: 46 }}>
                          Stake Page &gt;
                        </AppButton>
                      ),
                    });
                    return;
                  }
                  if (!wallet.acc.chain) return;
                  return wallet.write.writeContractAsync({
                    abi: HeroEdition2,
                    address: chainInfo.HeroEdition2.address,
                    functionName: 'mint',
                    args: [chainInfo.WETH],
                  });
                },
                'Claim hero mystery boxes',
                () => {
                  infos.refetch();
                  const handler = GlobalVar.modal.confirm({
                    wrapClassName: 'app-confirm-wrap',
                    centered: true,
                    maskClosable: true,
                    title: 'CONGRATULATIONS',
                    icon: null,
                    width: 600,
                    content: (
                      <ConfirmContent
                        img={
                          <div className="success-imgs">
                            <img className="box" src={ViewBox2} width={128} />
                            <img className="box-s" src={ViewBoxs} width={234} />
                            <img className="box-bg" src={ViewBoxBg} width={272} />
                          </div>
                        }
                        title="You've successfully claimed your Crypto Rumble Hero Mystery Box!"
                        des="You're so lucky! Enjoy your mystery box."
                      />
                    ),
                    footer: (
                      <AppButton onClick={() => handler.destroy()} className="app-confirm-btn" style={{ width: 130, height: 46 }}>
                        Okay
                      </AppButton>
                    ),
                  });
                },
              )}
            >
              Claim Now!
            </AppButton>
          </div>
        </div>
        <div className="view">
          <div className="bg">
            <img src={ViewBg2} />
            <div className="bg2-div" />
          </div>
          <div className="title">Rules</div>
          <div className="pcp">
            <PageAirdropPcP fontSize={16} fontWeight={500}>
              The staked {wallet.chainInfo.nativeCurrency.symbol} will be deposited into the Zypher pool for yield generation and Zypher's points
            </PageAirdropPcP>
            <div className="hr" />
            <PageAirdropPcP fontSize={16} fontWeight={500}>
              If the staked amount is &gt;= {SymbolValueMap[wallet.chainInfo.nativeCurrency.symbol] || 'x'} {wallet.chainInfo.nativeCurrency.symbol}, you will receive 1 hero mystery box
            </PageAirdropPcP>
            <div className="hr" />
            <PageAirdropPcP fontSize={16} fontWeight={500}>
              The maximum number of hero mystery boxes available is 10,000
            </PageAirdropPcP>
          </div>
        </div>
      </PageIndexStyle>
    </LayoutDefault>
  );
};

const DataItem: React.FC<{ img: React.ReactNode; name: string; value: string }> = (props) => {
  return (
    <DataItemStyle>
      {props.img}
      <div className="name">{props.name}</div>
      <div className="value">{props.value}</div>
    </DataItemStyle>
  );
};

const ConfirmContent: React.FC<{ img: React.ReactNode; title: string; des: string }> = (props) => {
  return (
    <ConfirmContentStyle>
      {props.img}
      <div className="title">{props.title}</div>
      <div className="des">{props.des}</div>
    </ConfirmContentStyle>
  );
};

const ConfirmContentStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > .title {
    font-size: 16px;
    font-weight: 700;
  }
  > .des {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
  }
  > .success-imgs {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    > .box {
      position: relative;
      z-index: 4;
    }
    > .box-s {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    > .box-bg {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
`;

const DataItemStyle = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  height: 44px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 12px 0 8px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  font-weight: 400;
  > img {
    user-select: none;
  }
  > .value {
    color: rgba(246, 185, 69, 1);
  }
  > .name {
    color: #fff;
  }
`;

const PageIndexStyle = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  > .view {
    position: relative;
    z-index: 1;
    min-height: 700px;
    color: #fff;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    + .view {
      padding-top: 0;
    }
    > .bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      pointer-events: none;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      > div {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgba(18, 18, 18, 0.5) 0%, #121212 100%);
        &.bg2-div {
          background: linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 0.7) 50%, rgba(0, 0, 0, 1) 100%);
        }
      }
    }

    > .title {
      font-size: 32px;
      font-weight: 700;
    }
    > .des {
      font-size: 16px;
      font-weight: 400;
    }
    > .content {
      width: 100vw;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .datas {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        > .hr {
          background-color: rgba(255, 255, 255, 0.1);
          width: 1px;
          height: 20px;
        }
      }
      > .box {
        margin-left: 40px;
        animation: box-will-open 2.5s ease-in-out infinite;
        pointer-events: none;
        position: relative;
        z-index: 10;
        user-select: none;
      }
      @keyframes box-will-open {
        10% {
          transform: rotate(15deg) scale(0.8);
        }
        20% {
          transform: rotate(-10deg);
        }
        30% {
          transform: rotate(5deg) scale(0.9);
        }
        40% {
          transform: rotate(-5deg) scale(1.1);
        }
        50%,
        100% {
          transform: rotate(0deg) scale(1);
        }
      }
      > .progress {
        font-size: 64px;
        font-weight: 400;
        background-image: linear-gradient(180deg, #f8df74 23.44%, #f2d03e 61.46%);
        background-clip: text;
        color: transparent;
        > span {
          font-size: 64px;
          font-weight: 400;
          background-image: linear-gradient(180deg, #909af9 13.54%, #515fe4 69.27%);
          background-clip: text;
          color: transparent;
          margin-left: 20px;
        }
      }
    }
    > .pcp {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      > .hr {
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }
`;
