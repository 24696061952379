import { lineaTestnet } from 'viem/chains';

export const SupportChains = [lineaTestnet] as const;

SupportChains.forEach((chain) => {
  (chain as any).iconUrl = `/chain/${chain.id}.svg`;
});

export const SupportChainIds = SupportChains.map((i) => i.id);

export type ISupportChainIds = (typeof SupportChainIds)[number];

export function IsSupportChainId(chainId: number): chainId is ISupportChainIds {
  return SupportChainIds.includes(chainId as any);
}
