import { ThemeConfig } from 'antd';

export const AntdTheme: ThemeConfig = {
  token: {
    colorBgElevated: '#4157be',
    colorBgContainer: '#002e6b',
    colorText: '#ececec',
    colorTextDescription: '#cbcbcb',
  },
  components: {
    Table: {
      borderColor: '#ffffff14',
      headerSplitColor: '#ffffff14',
      colorText: '#00c192',
      headerColor: '#00ffc1',
      cellPaddingBlockSM: 1,
      cellPaddingInlineSM: 1,
      rowHoverBg: '#002244',
    },
    Segmented: {
      trackBg: '#f5f5f517',
      itemColor: '#ffffff85',
      itemSelectedBg: '#9dbbff',
      itemSelectedColor: '#003e54e0',
    },
  },
};
