import { useRecoilValue } from 'recoil';
import { stateAppChain } from '../states/wallet';
import { useAccount, useChains, useClient, useConnect, usePublicClient, useTransactionConfirmations, useWriteContract } from 'wagmi';
import { useAddRecentTransaction, useChainModal, useConnectModal } from '@rainbow-me/rainbowkit';
import { Spin, Tag, message, notification } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { GlobalVar } from 'constants/constants';
import { is0xString } from 'utils';
import { TransactionReceipt, zeroAddress } from 'viem';

export const useWallet = () => {
  const chainInfo = useRecoilValue(stateAppChain);
  const addTx = useAddRecentTransaction();
  const acc = useAccount();
  const chainModal = useChainModal();
  const connModal = useConnectModal();
  const write = useWriteContract();
  const publicClient = usePublicClient();
  const hasError = () => {
    if (!acc.address) {
      connModal.openConnectModal?.();
      return true;
    }
    if (!acc.chain) {
      chainModal.openChainModal?.();
      return true;
    }
    return false;
  };
  const res = {
    chainInfo,
    acc,
    address: acc.address || zeroAddress,
    write,
    hasError,
    publicClient,
    requestTx<T>(cb: (arg: T) => Promise<any>, description = '', onSuccess: null | ((res: TransactionReceipt) => any) = null) {
      let pending = false;
      return async (arg: T) => {
        console.log('requestTx', arg);
        if (pending) return;
        if (!acc.address) return connModal.openConnectModal?.();
        if (!acc.chain) return chainModal.openChainModal?.();
        if (!publicClient) return;
        const key = Date.now().toString();
        pending = true;
        try {
          const hash = await cb(arg);
          if (!hash) return;
          if (!is0xString(hash)) return hash;
          addTx({ hash, description });
          const res = await publicClient.waitForTransactionReceipt({ hash, confirmations: 1 });
          onSuccess?.(res);
          return res;
          // return await new Promise((resolve) => {
          //   GlobalVar.notification.open({
          //     key,
          //     message: description,
          //     onClose: () => resolve(null),
          //     description: <AddTxRequest hash={hash} wallet={res} description={description} nkey={key} />,
          //     duration: 0,
          //   });
          // });
        } catch (e) {
          const msg = String(e);
          if (msg.match('User rejected the request')) return GlobalVar.notification.destroy(key);
          return msg;
        } finally {
          pending = false;
        }
      };
    },
  };
  return res;
};

const AddTxRequest: React.FC<{ hash: `0x${string}`; nkey: string; description: string; wallet: ReturnType<typeof useWallet> }> = (props) => {
  const addTx = useAddRecentTransaction();
  const confirmations = useTransactionConfirmations({ hash: props.hash });

  useEffect(() => {
    addTx({ hash: props.hash, description: props.description });
  }, []);

  const url = useMemo(() => `${props.wallet.publicClient?.chain.blockExplorers?.default.url}/tx/${props.hash}`, [props.hash]);
  return (
    <PageStyle>
      <Tag icon={confirmations.data ? <CheckCircleOutlined /> : <SyncOutlined spin />} color={confirmations.data ? 'success' : 'processing'}>
        Transaction Confirmations: {confirmations.data?.toString() || 0}
      </Tag>
      <Link to={url} target="_blank">
        {url}
      </Link>
    </PageStyle>
  );
};

const PageStyle = styled.div``;
