import Phaser, { AUTO, Game } from 'phaser';
import { SpinePlugin } from '@esotericsoftware/spine-phaser';
import { Boot } from './scenes/Boot';
import { MainMenu } from './scenes/MainMenu';

//  Find out more information about the Game Config at:
//  https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
const config: Phaser.Types.Core.GameConfig = {
  type: AUTO,
  backgroundColor: 'transparent',
  transparent: true,
  scene: [Boot, MainMenu],
  input: { mouse: { preventDefaultWheel: false } },
  plugins: {
    scene: [{ key: 'spine.SpinePlugin', plugin: SpinePlugin, mapping: 'spine' }],
  },
};
const clientWidth = window.document.body.clientWidth;
const isMobile = clientWidth < 400;
const diff = isMobile ? 300 : 0;

const scale = 0.13;
export const PageIndexHeroes = [
  { name: 'Thief', atks: ['FallenProphet'], left: isMobile ? 1270 : 430, top: 760, scaleX: isMobile ? scale * 0.6 : scale, scaleY: isMobile ? scale * 0.6 : scale },
  {
    name: 'RadiantMage',
    atks: ['FallenProphet', 'ScarletMage'],
    left: isMobile ? 1180 : 680,
    top: isMobile ? 1100 : 1200,
    scaleX: isMobile ? scale * 0.7 : scale,
    scaleY: isMobile ? scale * 0.7 : scale,
  },
  { name: 'Magician', atks: ['FallenProphet'], left: isMobile ? 1000 : 920, top: 1380, scaleX: isMobile ? scale * 0.8 : scale, scaleY: isMobile ? scale * 0.8 : scale },

  { name: 'FallenProphet', atks: ['Magician'], left: isMobile ? 1760 : 2320, top: 1250, scaleX: isMobile ? -scale * 0.7 : -scale, scaleY: isMobile ? scale * 0.7 : scale },
  { name: 'ScarletMage', atks: ['Magician', 'RadiantMage', 'Magician'], left: isMobile ? 1900 : 2000, top: 1390, scaleX: isMobile ? -scale * 0.8 : -scale, scaleY: isMobile ? scale * 0.8 : scale },

  // { name: 'DwarfGunslinger', atks: [], left: isMobile ? 1000 : 1280, top: 1380, scaleX: isMobile ? scale * 0.8 : scale, scaleY: isMobile ? scale * 0.8 : scale },
  // { name: 'GodOfWar', atks: [], left: isMobile ? 1000 : 1280, top: 1280, scaleX: isMobile ? scale * 0.8 : scale, scaleY: isMobile ? scale * 0.8 : scale },
  // { name: 'HeadlessRider', atks: [], left: isMobile ? 1000 : 1280, top: 780, scaleX: isMobile ? scale * 0.8 : scale, scaleY: isMobile ? scale * 0.8 : scale },
  // { name: 'StoneWarrior', atks: [], left: isMobile ? 1000 : 1280, top: 480, scaleX: isMobile ? scale * 0.8 : scale, scaleY: isMobile ? scale * 0.8 : scale },
];

export const CryptoRumbleHeroesStart = (option: Partial<Phaser.Types.Core.GameConfig>) => {
  console.log('CryptoRumbleHeroesStart');
  return new Game({ ...config, ...option });
};
