import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import 'assets/scss/index.scss';
import '@rainbow-me/rainbowkit/styles.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { RecoilRoot } from 'recoil';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import { PageIndex } from 'pages/index';
import { AppInitVh } from 'utils/AppInitVh';
import { wagmiConfig } from 'constants/wagmi';
import { RainbowKitTheme } from 'constants/RainbowKitTheme';
import { AntdTheme } from 'constants/AntdTheme';
import { PageAirdrop } from 'pages/airdorp';
import { PageGame } from 'pages/game';

const queryClient = new QueryClient();
// const addRecentTransaction = useAddRecentTransaction();
createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppInitVh />
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider coolMode showRecentTransactions theme={RainbowKitTheme}>
          <ConfigProvider theme={AntdTheme}>
            <RecoilRoot>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<PageIndex />} />
                  <Route path="/airdrop" element={<PageAirdrop />} />
                  <Route path="/game" element={<PageGame />} />
                </Routes>
              </BrowserRouter>
            </RecoilRoot>
          </ConfigProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
);
